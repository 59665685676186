import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Row } from './helpers';
import { Select } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Select from '@mfrm/mfcl/Select'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Select} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Select label=\"Default\" name=\"default-select\">\n  <option value=\"\" />\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>\n<Select label=\"Disabled\" name=\"default-disabled\" disabled>\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>\n<Select label=\"Error\" name=\"default-error\" error>\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Row,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select label="Default" name="default-select" mdxType="Select">
    <option value="" />
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
  <Select label="Disabled" name="default-disabled" disabled mdxType="Select">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
  <Select label="Error" name="default-error" error mdxType="Select">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <Playground __position={2} __code={'<Select name=\"sm\" label=\"sm\" placeholder=\"sm\" size=\"sm\">\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>\n<Select name=\"md\" label=\"md\" placeholder=\"md\" size=\"md\">\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>\n<Select name=\"lg\" label=\"lg\" placeholder=\"lg\" size=\"lg\">\n  <option value=\"1\">Option 1</option>\n  <option value=\"2\">Option 2</option>\n  <option value=\"3\">Option 3</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Row,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select name="sm" label="sm" placeholder="sm" size="sm" mdxType="Select">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
  <Select name="md" label="md" placeholder="md" size="md" mdxType="Select">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
  <Select name="lg" label="lg" placeholder="lg" size="lg" mdxType="Select">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Select>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      